<template>
  <div>
    <heads :routeUrl="url"></heads>
    <div class="top" v-if="isReady">
      <div class="w">
        <div class="top-box">
          <div class="top-name d-flex align-center">
            {{ list.teacher.name }}
            <span
              class="d-flex align-center"
              v-for="idx in list.teacher.labelName"
              :key="idx"
              >{{ idx }}</span
            >
          </div>
          <div class="top-old">教学经验：{{ list.teacherOld }}年教学经验</div>
          <div class="top-brief">个人简介：</div>
          <div class="top-brief-txt">
            {{ list.teacher.teacherIntro }}
          </div>
          <div class="top-img">
            <img :src="list.teacher.teacherImg" alt="" />
          </div>
        </div>
      </div>
    </div>
    <div class="w" v-if="isReady">
      <!-- 面包屑 -->
      <div class="breadcrumb d-flex align-center">
        <div class="breadcrumb-title d-flex align-center cursor">
          <span></span>
          师资阵容
          <span></span>
        </div>
        <ul class="breadcrumb-list d-flex align-center">
          <li class="breadcrumb-item cursor">{{ list.teacher.name }}</li>
          <!-- <li class="breadcrumb-item">></li> -->
          <!-- <li class="breadcrumb-item cursor">第一节教育</li> -->
        </ul>
      </div>
      <div class="coursebox d-flex justify-between">
        <div class="coursebox-l">
          <div class="title d-flex align-center">
            <span></span>
            {{ list.teacher.name }}老师课程
          </div>
          <ul class="list">
            <li
              class="item d-flex align-center"
              v-for="items in teachList"
              :key="items"
            >
              <div class="order">
                <img src="../../assets/img/live_head.png" alt="" />
              </div>
              <div class="detail d-flex align-center">
                <div class="detail-title">{{ items.name }}</div>
                <div class="detail-class">科目：{{ items.course.name }}</div>
                <!-- <div
                  class="detail-btn d-flex align-center justify-center cursor"
                >
                  听课学习
                </div> -->
              </div>
            </li>
          </ul>
        </div>
        <div class="coursebox-r">
          <div class="title d-flex align-center">
            <span></span>
            推荐课程
          </div>
          <div
            class="item d-flex"
            v-for="(lists, item) in list.sourcePackagesTeacherList"
            :key="item"
            :class="{ item_border: item == 1 }"
            @click="courseDetail(lists.id)"
          >
            <div class="item-img">
              <img :src="lists.icon" alt="" />
            </div>
            <div class="item-l d-flex flex-column justify-between">
              <div class="item-title">{{ lists.name }}</div>
              <div class="item-name">主讲老师：{{ list.teacher.name }}</div>
              <div class="item-bottom d-flex align-end justify-between">
                <div class="item-num">{{ lists.salsCount }}人购买</div>
                <div class="item-btn d-flex align-center justify-center cursor">
                  立即预约
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="swiper">
        <div class="title d-flex align-center">
          <span></span>
          其他优秀教师
        </div>
        <div class="swiper-container swiper-grab">
          <div class="swiper-wrapper">
            <div
              class="item swiper-slide"
              v-for="(swiper, idx) in list.teacherList"
              :key="idx"
              @click="goDetail(swiper.id)"
            >
              <div class="item-img">
                <img :src="swiper.teacherImg" alt="" />
              </div>
              <div class="item-po d-flex align-center">
                <div class="item-title d-flex align-center">
                  <span></span>
                  {{ swiper.name }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="swiper-button-prev grab_prev cursor"></div>
        <div class="swiper-button-next grab_next cursor"></div>
      </div>
    </div>
    <footers></footers>
  </div>
</template>

<script>
import { Swiper } from "vue-awesome-swiper";
export default {
  data() {
    return {
      total: 0, // 记录总条数
      display: 12, // 每页显示条数
      current: 1, // 当前的页数
      url: "teachers",
      id: "",
      list: "", // 目录
      isReady: false,
      teachList: ""
    };
  },

  watch: {
    $route: function (newVal, oldVal) {
      console.log(oldVal); //oldVa 上一次url
      console.log(newVal); //newVal 这一次的url
      console.log(newVal != oldVal)
      if (newVal != oldVal) {
        location.reload();
      }
    }
  },
  mounted() {
    this.id = this.$route.query.id;
    this.getDetails();
    this.teacherCourse();
  },
  methods: {
    goOrder(id) {
      this.$router.push({
        name: `order`,
        query: {
          id: id
        }
      });
    },
    pagechange(currentPage) {
      console.log(currentPage); //该参数就是当前点击的页码数
      // ajax请求, 向后台发送 currentPage, 来获取对应的数据
      this.current = currentPage;
      this.getlist();
      const currentY =
        document.documentElement.scrollTop || document.body.scrollTop;
      this.scrollAnimation(currentY, 0);
    },
    scrollAnimation(currentY, targetY) {
      // 获取当前位置方法
      // 计算需要移动的距离
      let needScrollTop = targetY - currentY;
      let _currentY = currentY;
      setTimeout(() => {
        // 一次调用滑动帧数，每次调用会不一样
        const dist = Math.ceil(needScrollTop / 10);
        _currentY += dist;
        window.scrollTo(_currentY, currentY);
        // 如果移动幅度小于十个像素，直接移动，否则递归调用，实现动画效果
        if (needScrollTop > 10 || needScrollTop < -10) {
          this.scrollAnimation(_currentY, targetY);
        } else {
          window.scrollTo(_currentY, targetY);
        }
      }, 1);
    },
    //详情
    getDetails() {
      let _this = this;
      _this.$api
        .teacherRecommend({
          teacherId: _this.id
        })
        .then(res => {
          console.log(res);
          if (!res.data.teacher || res.data.teacher.statu == 0) {
            _this.$router.replace("teachers");
          }
          _this.list = res.data;
          // _this.getPackagesLabels()
          _this.isReady = true;
          setTimeout(function () {
            new Swiper(".swiper-grab", {
              loop: false, // 循环模式选项
              slidesPerView: 4,
              spaceBetween: 58,
              observer: true,
              observeParents: true,
              navigation: {
                nextEl: ".grab_next ",
                prevEl: ".grab_prev"
              }
            });
          }, 500);
        })
        .catch(err => {
          console.log(err);
        });
    },
    teacherCourse() {
      let _this = this;
      _this.$api
        .teacherCourse({
          teacherId: _this.id
        })
        .then(res => {
          console.log(res);
          _this.teachList = res.data.ChargeSourceCourseLessonList;
        })
        .catch(err => {
          console.log(err);
        });
    },
    goDetail(id) {
      this.$router.push({
        name: `teachersDetail`,
        query: {
          id: id
        }
      });
    },
    courseDetail(id) {
      this.$router.push({
        name: `courseDetail`,
        query: {
          id: id
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
.top {
  height: 350px;
  min-width: 1200px;
  background: url(../../assets/img/teacher_bg.png) center no-repeat;
  .top-box {
    margin-left: 200px;
    padding-top: 50px;
    box-sizing: border-box;
    position: relative;
    .top-name {
      font-size: 25px;
      font-weight: bold;
      color: #f1f1f1;
      span {
        // width: 88px;
        padding: 0 15px;
        height: 22px;
        border: 1px solid #ffc833;
        border-radius: 11px;
        margin-left: 9px;
        font-size: 12px;
        color: #ffc833;
      }
    }
    .top-old {
      font-size: 14px;
      color: #ffffff;
      opacity: 0.7;
      margin-top: 24px;
    }
    .top-brief {
      font-size: 16px;
      font-weight: bold;
      color: #ffffff;
      margin-top: 26px;
    }
    .top-brief-txt {
      width: 397px;
      height: 62px;
      font-size: 14px;
      color: #ffffff;
      line-height: 24px;
      opacity: 0.7;
      margin-top: 14px;
    }
    .top-img {
      width: 193px;
      height: 193px;
      border: 3px solid #a189ff;
      position: absolute;
      top: 72px;
      right: 272px;
    }
  }
}
// 面包屑
.breadcrumb {
  margin-top: 29px;
  .breadcrumb-title {
    font-size: 16px;
    font-weight: bold;
    color: #444444;
    // padding-left: 15px;
    // box-sizing: border-box;
    // border-left: 4px solid #5f2eff;
    span:first-child {
      display: inline-block;
      width: 4px;
      height: 16px;
      background: #5f2eff;
      border-radius: 2px;
      margin-right: 11px;
    }
    span:last-child {
      width: 1px;
      height: 10px;
      background: #cccccc;
      border-radius: 1px;
      margin-left: 12px;
    }
  }
  .breadcrumb-item {
    font-size: 14px;
    color: #888888;
    margin-left: 12px;
  }
}
.coursebox {
  width: 1200px;
  height: 380px;
  background: #ffffff;
  box-shadow: 0px 0px 16px 0px rgba(222, 222, 222, 0.4);
  margin-top: 30px;
  .coursebox-l {
    padding: 0 20px;
    padding-bottom: 30px;
    box-sizing: border-box;
    flex: 1;
    .title {
      padding: 30px 0;
      padding-bottom: 14px;
      font-size: 18px;
      font-weight: bold;
      color: #5f2eff;
      box-sizing: border-box;
      span {
        width: 4px;
        height: 20px;
        background: #5f2eff;
        border-radius: 2px;
        margin-right: 10px;
        display: block;
      }
    }
    .list {
      height: 271px;
      overflow-y: scroll;
      .item {
        height: 69px;
        padding-right: 15px;
        box-sizing: border-box;
        .order {
          width: 16px;
          height: 16px;
          position: relative;
          margin-left: 16px;
          margin-right: 30px;
          img {
            position: relative;
          }
          &::before {
            content: "";
            display: block;
            height: 69px;
            width: 2px;
            background: #f4f0ff;
            position: absolute;
            top: 8px;
            left: 7px;
          }
        }
        .detail {
          position: relative;
          box-sizing: border-box;
          border-bottom: 1px solid #f6f9ff;
          width: 100%;
          height: 100%;
          .detail-title {
            width: 268px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-size: 18px;
            color: #333333;
          }
          .detail-class {
            font-size: 14px;
            color: #888888;
            margin-left: 58px;
          }
          .detail-num {
            font-size: 14px;
            color: #888888;
            margin-left: 74px;
          }
          .detail-btn {
            width: 80px;
            height: 22px;
            border: 1px solid #5f2eff;
            border-radius: 11px;
            margin-left: auto;
            font-size: 12px;
            color: #5f2eff;
          }
        }
      }
      :last-child {
        .order::before {
          display: none;
        }
      }
    }
  }
  .coursebox-r {
    width: 350px;
    height: 380px;
    background: #ffffff;
    box-shadow: 0px 0px 16px 0px rgba(222, 222, 222, 0.4);
    padding: 0 20px;
    box-sizing: border-box;
    .title {
      padding: 30px 0;
      padding-bottom: 16px;
      font-size: 18px;
      font-weight: bold;
      color: #5f2eff;
      box-sizing: border-box;
      span {
        width: 4px;
        height: 20px;
        background: #5f2eff;
        border-radius: 2px;
        margin-right: 10px;
        display: block;
      }
    }
    .item {
      .item-img {
        width: 132px;
        height: 132px;
        border-radius: 4px;
      }
      .item-l {
        margin-left: 16px;
        padding: 8px 0;
        box-sizing: border-box;
        .item-title {
          width: 160px;
          font-size: 16px;
          font-weight: bold;
          color: #333333;
          line-height: 22px;
        }
        .item-bottom {
          .item-num {
            font-size: 10px;
            color: #5f2eff;
          }
          .item-btn {
            width: 80px;
            height: 24px;
            border: 1px solid #f53444;
            border-radius: 12px;
            font-size: 14px;
            color: #f53444;
          }
        }
      }
    }
    .item_border {
      margin-top: 21px;
      padding-top: 14px;
      border-top: 2px dashed #cccccc;
      box-sizing: border-box;
    }
  }
}
.swiper {
  width: 1200px;
  height: 364px;
  background: #ffffff;
  box-shadow: 0px 0px 16px 0px rgba(222, 222, 222, 0.4);
  margin-top: 20px;
  padding: 30px 20px;
  box-sizing: border-box;
  position: relative;
  .title {
    font-size: 18px;
    font-weight: bold;
    color: #5f2eff;
    box-sizing: border-box;
    span {
      width: 4px;
      height: 20px;
      background: #5f2eff;
      border-radius: 2px;
      margin-right: 10px;
      display: block;
    }
  }
  .swiper-grab {
    height: 266px;
    width: 1040px;
    // margin: 0 auto;
    margin-top: 20px;
    .item {
      width: 216px !important;
      height: 266px;
      background: #ffffff;
      border-radius: 4px;
      // overflow: hidden;
      // background-color: pink;
      .item-img {
        width: 216px !important;
        height: 266px;
        border-radius: 4px;
      }
      .item-po {
        width: 216px;
        height: 76px;
        position: absolute;
        left: 0;
        bottom: 0;
        background: url(../../assets/img/teach_po.png) center no-repeat;
        .item-title {
          font-size: 18px;
          font-weight: bold;
          color: #ffffff;
          box-sizing: border-box;
          margin-left: 10px;
          span {
            width: 4px;
            height: 20px;
            background: #5f2eff;
            border-radius: 2px;
            margin-right: 10px;
            display: block;
          }
        }
      }
    }
  }
  .grab_prev {
    width: 31px;
    height: 51px;
    background: url(../../assets/img/teach_left.png) center no-repeat;
    left: 23px;
    cursor: pointer;
    z-index: 100;
    outline: none;
    margin-top: 0;
  }

  .grab_next {
    width: 31px;
    height: 51px;
    background: url(../../assets/img/teach_right.png) center no-repeat;
    right: 23px;
    cursor: pointer;
    z-index: 100;
    outline: none;
    margin-top: 0;
  }
}
</style>
